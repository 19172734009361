<template>
  <v-container class="pt-9">
    <v-row class="mb-6" no-gutters justify="end">
      <v-col cols="auto">
        <DatabaseAdder @successAdded="fetchDatabases">
          <template v-slot:activator="{ on, attr }">
            <v-btn
              class="btn-add-database"
              color="primary"
              rounded
              v-bind="attr"
              :disabled="disableActionsBtn"
              v-on="on"
            >
              {{ $t('page.databases.add_database_title') }}
            </v-btn>
          </template>
        </DatabaseAdder>
      </v-col>
    </v-row>

    <IssueCard
      v-if="error"
      class="mt-13"
      :message="$t('api_errors.data_error_refresh_page')"
      error
    />

    <v-card v-else outlined>
      <v-data-table
        class="database-table"
        :headers="tableHeaders"
        :items="databases"
        :items-per-page="-1"
        :search="search"
        :item-class="() => 'database-tr'"
        :loading="isLoadingDatabases || isDeleteing"
        :no-data-text="$t('page.databases.no_data_and_create_database')"
        :loading-text="`${$t('loading')}...`"
        border
        hide-default-footer
        style="width: 100%"
      >
        <!-- slot: item.actions -->
        <template v-slot:[`item.actions`]="{ item: database }">
          <v-btn icon>
            <v-icon
              small
              color="primary"
              :disabled="disableActionsBtn"
              @click="deleteDatabase(database)"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import DatabaseAdder from '@/components/DatabaseAdder/DatabaseAdder'

import { mapState } from 'vuex'

const tableHeaders = (vm) => [
  {
    text: vm.$t('database_name'),
    value: 'name',
    width: '25%'
  },
  {
    text: vm.$t('action'),
    value: 'actions',
    width: '25%'
  }
]

export default {
  name: 'Databases',

  components: {
    IssueCard,
    DatabaseAdder
  },

  data: (vm) => ({
    search: '',
    isDeleteing: false,
    error: false
  }),

  computed: {
    ...mapState({
      databases: (state) => state.databases.databases,
      isLoadingDatabases: (state) => state.databases.isLoadingDatabases
    }),
    tableHeaders,

    disableActionsBtn() {
      return this.isDeleteing || this.isLoadingDatabases
    }
  },

  created() {
    this.fetchDatabases()
  },

  methods: {
    deleteDatabase(database) {
      const confirm = window.confirm(
        this.$t('confirm_delete_item', {
          name: database.name
        })
      )

      if (!confirm) return

      this.isDeleteing = true
      this.$store
        .dispatch('databases/deleteDatabase', database)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_deleted')
          })

          return this.fetchDatabases()
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          return Promise.reject(error)
        })
        .finally(() => {
          this.isDeleteing = false
        })
    },
    fetchDatabases() {
      this.error = false
      this.$store.dispatch('databases/fetchDatabases').catch(() => {
        this.error = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default.btn-add-database {
  padding: 20px 2.25rem;
  font-size: 1.25rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.database-table {
  ::v-deep .v-data-table__wrapper {
    max-height: calc(100vh - 190px);
  }

  ::v-deep .v-data-table-header {
    tr th {
      font-size: 1.5rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .database-tr {
    td {
      overflow: hidden;
      max-width: 120px;
      font-size: 1.125rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
